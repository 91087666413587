"use strict"

import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"
import React from "react"
import styled from "styled-components"

import theme from "../../theme"
import Image from "../parts/image"
import { Spacer } from "../parts/styled"
import { RandNum } from "../parts/utils"

// ###################################
const TypographyS = styled(Typography)`
  border-bottom: 1px solid silver;
  margin-bottom: ${theme.spacing(3)}px;
  padding-bottom: ${theme.spacing(3)}px;
`

// ###################################
const data = {
  h4: "埋戻し",
  p: [
    {
      main: [
        "埋戻し材料",
        "埋戻し土は、特に現場発生材を時は、設計条に合致した出来るだけ良質な材料を選別して用いること。",
      ],
      sub: [],
    },
    {
      main: [
        "敷均し",
        "埋戻し土は、小型ブルトーザーや人力により、一定の厚さ、且つ平坦に敷き均す。",
      ],
      sub: [],
    },
    {
      main: [
        "締固め",
        "締固め箇所に最適な機材を使用して、各層毎が均一に所定の密度となるよう、締固めること。",
      ],
      sub: [
        "（1）仕上げ層は 30cm/層 以下とすること。",
        "（2）締固めの基準は下記の通りとする。",
        "『住宅・都市整備公団施工管理基準（平成 7年 5月）』による。",
        "① JIS A 1210（突固めによる土の締固めの方法）が定める最大乾燥密度の 85% 以上に締固める。",
        "② 基準となる最大乾燥密度を決め難い土（各種の土が混合し、その割合が変化する場合や、試料の乾燥程度により最大乾燥密度や最適含水比が変化する土等）及び施工含水比を、上記 ① が定める範囲に調整することが困難な土の場合は、空気間隙率 2〜15%の範囲に締固める。",
      ],
    },
    {
      main: [
        "締固めの機械",
        "使用する機械は、締固め箇所に最適な機種を選定する。",
      ],
      sub: [
        "① 擁壁底版上部（本製品の控え部分に囲まれる部分を除く）の埋戻し土を転圧する場合は、自重11トン級以下の自走式タイヤローラー、又はブルトーザーを使用する。",
        "② 製品の控え部分に囲まれる部分の埋戻し土を転圧する場合は、自重 1t未満の小型振動ローラー、又は振動コンパクター等を使用する。",
      ],
    },
    {
      main: ["埋戻し留意点", ""],
      sub: [
        "① 擁壁のポスト鉄筋挿入孔への打設コンクリートの強度が十分発生していることを確認し、擁壁が損傷を受けないよう、注意すること。",
        "② 雨水や地表面水の排水には、十分配慮すること。また、透水の多い箇所については、止水板等の処理を講ずること。",
        "③ 埋戻し土は、大きく固結したものや、多量の水分を含んだものは使用しないこと。",
        "④ 透水層を正確に確保するよう、十分配慮すること。",
      ],
    },
  ],
}

// ###################################
const Page09 = () => (
  <>
    <Grid container spacing={0}>
      {/*############################################################*/}
      <Grid item sm={12} md={5}>
        <Image
          filename={`logo-ttype-block.jpg`}
          width={`${theme.prime.sp101}px`}
        />

        <Spacer v={5} />
      </Grid>

      {/*############################################################*/}
      <Grid item sm={12} md={7}>
        <TypographyS variant="h4" component="h4">
          <b>{data.h4}</b>
        </TypographyS>

        <ol>
          {data.p.map((c, i) => (
            <>
              <Typography variant="subtitle1" component="li" key={RandNum()}>
                {c.main[0]}

                <p>{c.main[1]}</p>
              </Typography>

              <ul style={{ listStyleType: `none` }}>
                {c.sub.map((cc) => (
                  <Typography
                    variant="subtitle1"
                    component="li"
                    key={RandNum()}
                  >
                    {cc}

                    <Spacer v={2} />
                  </Typography>
                ))}
              </ul>

              {i === 2 ? (
                <>
                  <Image filename={`fig13.jpg`} />
                  <Spacer v={5} />
                </>
              ) : null}

              {i !== 4 ? (
                <>
                  <Spacer v={3} />
                </>
              ) : null}
            </>
          ))}
        </ol>
      </Grid>
    </Grid>
  </>
)

export default Page09
