"use strict"

import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"
import React from "react"

import theme from "../../theme"
import Image from "../parts/image"
import { Spacer, TypographyS } from "../parts/styled"

// ###################################
const data = [
  {
    h4: "国土交通大臣認定製品",
    p:
      "「T型ブロック Wタイプ」は、宅地造成等規制法に基づく国土交通大臣認定製品です。なお、認定条件の詳細（フーチング基礎形状を含む）につきましては、担当者までお問い合わせ下さい。",
  },
  {
    h4: "地上高さ（Wタイプ）",
    p: "土質試験により、土質状況を確認した場合",
  },
]
// ###################################
const Page03 = () => (
  <Grid container spacing={0}>
    {/*############################################################*/}
    <Grid item sm={12} md={6}>
      <Image
        filename={`logo-ttype-block.jpg`}
        width={`${theme.prime.sp101}px`}
      />

      <Spacer v={5} />

      <Image filename={`fig01.jpg`} width={`${theme.prime.sp389}px`} />

      <Spacer v={5} />
    </Grid>

    {/*############################################################*/}
    <Grid item sm={12} md={6}>
      {data.map((c, i) => (
        <>
          <TypographyS variant="h4" component="h4">
            <b>{c.h4}</b>
          </TypographyS>

          <Typography variant="subtitle1" component="p">
            {c.p}
          </Typography>

          <Spacer v={5} />

          {i === 0 ? (
            <>
              <Image filename={`fig03.jpg`} />

              <Spacer v={11} />
            </>
          ) : (
            <Image filename={`fig02.jpg`} />
          )}
        </>
      ))}
    </Grid>
  </Grid>
)

export default Page03
