"use strict"

import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"
import React from "react"
import styled from "styled-components"

import theme from "../../theme"
import Image from "../parts/image"
import { Spacer } from "../parts/styled"
import { RandNum } from "../parts/utils"

// ###################################
const TypographyS = styled(Typography)`
  border-bottom: 1px solid silver;
  margin-bottom: ${theme.spacing(3)}px;
  padding-bottom: ${theme.spacing(3)}px;
`

// ###################################
const data = [
  {
    h4: "施工全般",
    p: [
      "施工に当たっては設計時の計算における諸条件を十分理解し、現地の状況と照合し、計画を立てる必要があります。",
      "特に、地盤支持力が不足する場合は、栗石等による地盤の改良、又は置き換えコンクリート等の施工により、地盤支持力を十分満足する配慮が大切です。",
      "（図 1-1（a）（b）参照）",
    ],
  },
  {
    h4: "基礎工（フーチング基礎）",
    p: [
      "） 床掘により、設計図書に基づく十分な地盤支持力を確保することとし、必要に応じてクラッシャーラン等を敷き詰めて十分に転圧して、所要の均しコンクリートを施工すること。（図 1-1（c）参照）",
      "） 設計図が尻下りになっている場合は、滑動抵抗を考慮して現地の地盤をほぐさないよう、正確に仕上げること。また、機械等で掘り過ぎた場合は、必ずコンクリートにより、所定の断面を得られるまで復元すること。（図 2-1 参照）",
      "） 傾斜地に施工する場合は、現地の状況によっては「図 2-2」の「置換え部分」のような経済的な断面を考慮すること。",
      "） 立ち上がり鉄筋は、必ず所定の位置へ正確、且つ十分に固定すること。また、曲線部または曲折部のアンカー鉄筋の位置決めは、ブロック全面から正確に 90℃ の線上へ位置するよう、専用の定規等を用いて決定すること。（注意）立ち上がり鉄筋は、コンクリートの打設時に動く恐れがあるため、溶接等で十分固定すること。",
      "） 立ち上がり鉄筋の延長方向における所定ピッチは、1,000mm だが、施工時にブロック間に空隙を生じるので、「2〜3mm/標準型ブロック（2,000✕1,000）」の伸びを考慮すること。また、認定擁壁 Wタイプについては、所定の位置に防錆処理を施すこと。",
      "） コンクリートの打設に際し、基礎ブロック据付の所定の位置に 凹 部を必ず設けること。ちなみに、厚さ 2cm 程度の発泡スチロール板を適宜の寸法に切断して使用すれば、便利です。",
      "） 設計図に基づき、鉄筋を組み立て、型枠を施工し、所定のコンクリートを打設する。この時、コンクリートの設計基準強度は、24N/m㎡（認定擁壁の場合は、30N/m㎡ 以上）とすること。",
      "） 基礎延長は 20m 以内で縁切りし、エラスタイトで処理すること。",
    ],
  },
]
// ###################################
const Page05 = () => (
  <Grid container spacing={0}>
    {/*############################################################*/}
    <Grid item sm={12} md={5}>
      <Image
        filename={`logo-ttype-block.jpg`}
        width={`${theme.prime.sp101}px`}
      />

      <Spacer v={5} />
    </Grid>

    {/*############################################################*/}
    <Grid item sm={12} md={7}>
      <TypographyS variant="h4" component="h4">
        <b>{data[0].h4}</b>
      </TypographyS>

      <Typography variant="subtitle1" component="p">
        {data[0].p[0]}
        <br />
        <br />
        {data[0].p[1]}
        <br />
        {data[0].p[2]}
      </Typography>

      <Spacer v={5} />

      <Image filename={`fig05.jpg`} />

      <Spacer v={11} />

      {/*############################################################*/}
      <TypographyS variant="h4" component="h4">
        <b>{data[1].h4}</b>
      </TypographyS>

      <ol>
        {data[1].p.map((c, i) => (
          <>
            <Typography variant="subtitle1" component="li" key={RandNum()}>
              {c}
            </Typography>

            {i === 2 ? (
              <>
                <Spacer v={3} />

                <Image filename={`fig06.jpg`} />
              </>
            ) : null}

            {i === 5 ? (
              <>
                <Spacer v={3} />

                <Image filename={`fig07.jpg`} />
              </>
            ) : null}

            <Spacer v={5} />
          </>
        ))}
      </ol>
    </Grid>
  </Grid>
)

export default Page05
