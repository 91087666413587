"use strict"

import Grid from "@material-ui/core/Grid"
import React from "react"

import theme from "../../theme"
import Image from "../parts/image"
import { Spacer } from "../parts/styled"

const Page10 = () => (
  <Grid container spacing={6}>
    <Grid item xs={12} sm={6}>
      <Image
        filename={`logo-ttype-block.jpg`}
        width={`${theme.prime.sp101}px`}
      />

      <Spacer v={5} />

      <Image filename={`fig14.jpg`} />
    </Grid>

    <Grid item xs={12} sm={6}>
      <Image filename={`fig15.jpg`} />
    </Grid>
  </Grid>
)

export default Page10
