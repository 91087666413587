"use strict"

import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"
import React from "react"
import styled from "styled-components"

import theme from "../../theme"
import Image from "../parts/image"
import { Spacer } from "../parts/styled"

// ###################################
const TypographyS = styled(Typography)`
  border-bottom: 1px solid silver;
  margin-bottom: ${theme.spacing(3)}px;
  padding-bottom: ${theme.spacing(3)}px;
`

// ###################################
const data = [
  {
    h4: "施工の単純化とスピード化",
    p:
      "一般的にブロック積みや現場打ち擁壁は作業が極めて複雑ですが、「T型ブロック」による工法では、施工を単純化できるため、熟練工を必要とせず、工事をスピード化することができます。",
  },
  {
    h4: "工期の短縮",
    p:
      "「T型ブロック」による工法では、基礎コンクリートの打設終了後、製品を計画の位置へ据え付け、鉄筋挿入孔へ鉄筋を挿入し、生コンクリート定着後に埋め戻すだけで工事が完了するため、工期の大幅な短縮を図ることができます。",
  },
  {
    h4: "製品の均一化",
    p:
      "「T型ブロック」は、品質管理が行き届いた JIS 表示認定工場（兼）大臣認定工場で製造されるため、製品品質の保証された、非常に安全性の高い二次製品と言えます。",
  },
  {
    h4: "経済効果／敷地の有効利用",
    p:
      "「T型ブロック」は、垂直に施工することにより、傾斜地においては擁壁数量を著しく減少できるため、所要経費を大幅に節約できると共に、用地の有効利用にも寄与します。",
  },
]
// ###################################
const Page02 = () => (
  <Grid container spacing={0}>
    {/*############################################################*/}
    <Grid item sm={12} md={5}>
      <Image
        filename={`logo-ttype-block.jpg`}
        width={`${theme.prime.sp101}px`}
      />

      <Typography
        variant="h3"
        component="h3"
        style={{
          padding: `${theme.spacing(3)}px 0 0 ${theme.spacing(3)}px`,
          lineHeight: `${theme.prime.sp179}%`,
          letterSpacing: `-5px`,
        }}
      >
        今までの擁壁と
        <br />
        較べて下さい。
      </Typography>

      <Spacer v={5} />
    </Grid>

    {/*############################################################*/}
    <Grid item sm={12} md={7}>
      {data.map((c, i) => (
        <>
          <TypographyS variant="h4" component="h4">
            <b>{c.h4}</b>
          </TypographyS>

          <Typography variant="subtitle1" component="p">
            {c.p}
          </Typography>

          {i === 3 ? (
            <>
              <Spacer v={5} />

              <Image filename={`fig00.jpg`} />
            </>
          ) : (
            <Spacer v={11} />
          )}
        </>
      ))}
    </Grid>
  </Grid>
)

export default Page02
