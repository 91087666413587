"use strict"

import React from "react"

import Layout from "../components/layout"
import Image from "../components/parts/image"
import SEO from "../components/parts/seo"
import { Spacer } from "../components/parts/styled"
import Page02 from "../components/ttype/page02"
import Page03 from "../components/ttype/page03"
import Page04 from "../components/ttype/page04"
import Page05 from "../components/ttype/page05"
import Page06 from "../components/ttype/page06"
import Page08 from "../components/ttype/page08"
import Page09 from "../components/ttype/page09"
import Page10 from "../components/ttype/page10"

// ###################################
const Ttype = () => (
  <Layout>
    <SEO title={`T型ブロック`} />

    <Spacer v={11} />

    <Image filename={`catalog_page-0001.jpg`} width={`320px`} />

    <Spacer v={23} />

    <Page02 />

    <Spacer v={23} />

    <Page03 />

    <Spacer v={23} />

    <Page04 />

    <Spacer v={23} />

    <Page05 />

    <Spacer v={23} />

    <Page06 />

    <Spacer v={23} />

    <Page08 />

    <Spacer v={23} />

    <Page09 />

    <Spacer v={23} />

    <Page10 />

    <Spacer v={11} />
  </Layout>
)

export default Ttype
