"use strict"

import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"
import React from "react"
import styled from "styled-components"

import theme from "../../theme"
import Image from "../parts/image"
import { Spacer } from "../parts/styled"
import { RandNum } from "../parts/utils"

// ###################################
const TypographyS = styled(Typography)`
  border-bottom: 1px solid silver;
  margin-bottom: ${theme.spacing(3)}px;
  padding-bottom: ${theme.spacing(3)}px;
`

// ###################################
const data = [
  {
    h4: "ブロックの築設",
    p: [
      "） 築造にはクレーン車を使用し、通常の場合は、特殊作業員 2〜3名程度、普通作業員 4名程度で実施する。",
      "） ブロックの吊り込みは、吊り金具を使用する。",
      "） 根石の据え付けは、フーチング基礎に設けた立ち上がり鉄筋に合わせて据え付ける。この場合、根石の上面に水糸等を張り、高さを調整する。また同時に、法勾配が設計に合致するよう、ライナープレート等を用いて施工する。",
      "） 根石が完了したら、2段目まで積む。",
      "） 2段目（又は 3段目）を構築した後、ポスト鉄筋を上から挿入し、生コンクリートを注入する。この場合の生コンクリートの設計基準強度は 30N/m㎡ 以上とする。",
      "） 鉄筋挿入孔へ重鎮する生コンクリートは、ポスト鉄筋挿入後、振動機等により、十分締固めする。",
      "） 擁壁背後の埋戻しは、生コンクリート設計基準強度の 70% 以上に達した後に行なうこと。",
      "） 排水効果を高めるため、背面側に厚さ 30cm 以上の栗石、又は砂利を充填するか、耐久性の高い透水マット等を水抜き孔を結ぶよう最上部から最下部の孔までブロックの背面に沿って設ける。また、水抜きには、排水フィルターを付ける。",
    ],
  },
  {
    h4: "",
    p: [
      "鉄筋の仕様は、設計図書に従って下さい。",
      "空洞部分が発生しないよう、棒バイブレータを掛けて下さい。",
      "生コンクリートは下げ目に入れて下さい。",
    ],
  },
  {
    h4: "控え壁 中詰め材料の容量",
    p: ["T型ブロック Wタイプ 1㎡ 当たりの控え壁 詰めコト量（㎥）"],
  },
]
// ###################################
const Page08 = () => (
  <>
    <Grid container spacing={0}>
      {/*############################################################*/}
      <Grid item sm={12} md={5}>
        <Image
          filename={`logo-ttype-block.jpg`}
          width={`${theme.prime.sp101}px`}
        />

        <Spacer v={5} />
      </Grid>

      {/*############################################################*/}
      <Grid item sm={12} md={7}>
        <TypographyS variant="h4" component="h4">
          <b>{data[0].h4}</b>
        </TypographyS>

        <ol>
          {data[0].p.map((c, i) => (
            <>
              <Typography variant="subtitle1" component="li" key={RandNum()}>
                {c}
              </Typography>

              {i === 7 ? <Image filename={`fig10.jpg`} /> : null}

              <Spacer v={3} />
            </>
          ))}
        </ol>

        <ul>
          {data[1].p.map((c) => (
            <Typography variant="subtitle2" component="li" key={RandNum()}>
              {c}
            </Typography>
          ))}
        </ul>

        <Spacer v={5} />

        {/*############################################################*/}
        <TypographyS variant="h4" component="h4">
          <b>{data[2].h4}</b>
        </TypographyS>

        <Typography variant="subtitle1" component="p">
          {data[2].p[0]}
          {data[2].p[1]}
          {data[2].p[2]}
        </Typography>

        <Spacer v={3} />

        <Image filename={`fig11.jpg`} />
      </Grid>

      <Grid item xs={12}>
        <Spacer v={5} />

        <Image filename={`fig12.jpg`} />
      </Grid>
    </Grid>
  </>
)

export default Page08
