"use strict"

import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"
import React from "react"

import theme from "../../theme"
import Image from "../parts/image"
import { Spacer } from "../parts/styled"

const Page06 = () => (
  <Grid container spacing={0}>
    <Grid item xs={12}>
      <Image
        filename={`logo-ttype-block.jpg`}
        width={`${theme.prime.sp101}px`}
      />

      <Typography
        variant="h2"
        component="h2"
        style={{
          padding: `${theme.spacing(3)}px 0 0 ${theme.spacing(3)}px`,
          lineHeight: `${theme.prime.sp179}%`,
          letterSpacing: `-5px`,
        }}
      >
        <b>T型ブロックの擁壁築造手順</b>
      </Typography>

      <Spacer v={5} />

      <Image filename={`fig08.jpg`} />

      <Spacer v={11} />

      <Image filename={`fig09.jpg`} />
    </Grid>
  </Grid>
)

export default Page06
